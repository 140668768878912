a,
a:visited {
  color: white;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #343464;
  color: #ffffff;
  font-size: 17px;
  line-height: 1.5;
}

.center-image {
  display: flex;
  margin: 10px auto;
  max-width: 100%;
  max-height: 300px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 80vh;
  margin: 120px 20px;
}

.rounded-box {
  background-color: #8c86b6;
  border-radius: 15px;
  padding: 15px;
  margin: 10px;
  max-width: 440px;
  box-sizing: border-box;
}

.flex-table {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  transition: 0.5s;
  margin: auto;
}

.flex-table:hover {
  transition: 400ms;
}

.flex-row,
.flex-row-emp,
.flex-row-wide {

  text-align: center;
  padding: 4px;
  box-sizing: border-box;
}

.flex-cell {

  text-align: center;
  padding: 4px;
  box-sizing: border-box;

  &:last-child {
    border-right: 0;
  }
}

/*Challenge Text Style*/
.text-title {
  font-size: 2em;
}

.text-container {
  white-space: pre-line;
  max-width: 800px;
  margin: 0 auto;
  display: auto;
  align-items: center;
  text-align: center;
  font-size: 1.3em;
  line-height: 1em;
}

/*Code Contenier*/
.code-title {
  text-align: center;
  margin-bottom: 10px;
}

.code-container {
  width: 100%;
}

.code-content {
  overflow-x: auto;
  white-space: pre-wrap;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: #000000;
  font-size: 14px;
  line-height: 1.2;
}

/*Footer*/
footer {
  background-color: transparent;
  padding: 0px;
  margin-top: 10px;
  text-align: center;
}

footer p a {
  color: #f0e833 !important;
  text-decoration: none;
}

footer p a:hover {
  text-decoration: underline;
}

/*Responsive*/
@media all and (max-width: 767px),
(max-width: 1368px) {

  body{
    font-size: 13px;
  }

  .center-image {
    max-width: 100%;
    max-height: 170px;
    margin: 0 auto;
  }

  .container,
  .rounded-box {
    max-width: 340px;  
  }

  .flex-row,
  .flex-row-emp,
  .flex-row-wide {
    width: 900px;  
    box-sizing: border-box;

    &.first {
      width: 100%;
      border-right: 0;
    }
  }

  .text-container {
    text-align: center;
    max-width: 100%;
  }

  .flex-cell {
    width: 100%;
    border-right: 0;
  }

  .column {
    width: 100%;
  }
}

@media all and (max-width: 430px) {

  .flex-row,
  .flex-row-emp,
  .flex-row-wide {
    width: 100%;

    &.first {
      width: 100%;
      border-right: 0;
    }
  }

  .text-container {
    text-align: center;
    max-width: 100%;
  }

  .column {
    width: 100%;

    .flex-row,
    .flex-row-emp,
    .flex-row-wide {
      border-bottom: solid 1px;
    }
  }

  .flex-cell {
    width: 100%;
    border-right: 0;
  }
}
