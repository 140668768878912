.navbar {
  background-color: var(--navBg);
  height: 70px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 2rem;
  background: none;
  color: #f5f5f5;
  margin-top: 10px;
}

.nav-menu {
  background-color: #2d2d4d;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  overflow-y: auto;
  z-index: 2;
}

::-webkit-scrollbar {
  width: 20px;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
  padding: 0;
}

.navbar-toggle {
  background-color: var(--navBg);
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 10px;
}

.starknet {
  max-width: 100%;
  max-height: 51px;
  margin-top: 4px;
}

@media screen and (max-width: 768px),(max-width: 1368px) {
  .navbar {
    height: 50px;
  }
  .starknet {
    max-width: 100%;
    max-height: 38px;
  }
}

@media all and (max-width: 430px) {
  .navbar {
    height: 45px;
  }

  .starknet {
    max-width: 50%;
    max-height: auto;
  }
}
